<template>
	<div class="login_center">
		<div class="login">
			<div class="slogan">
				<h3
					class="enterpriseLogo d-flex align-items-end justify-content-center"
				>
					<div
						class="logo-rotate"
						style="color: #eed36d; transform: rotate(22deg); font-size: 52px"
					>
						{{ $t("Login.Joe") }}
					</div>

					<div class="ms-2 position-relative" style="font-size: 26px">
						<!-- <img
							class="tentSvg"
							:src="require('/src/assets/image/Shared/Login/帳篷 (1).svg')"
						/> -->
						<div>{{ $t("Login.CustomerService") }}</div>
					</div>
				</h3>
				<p class="enterpriseOnwer">
					{{ $t("Login.AICustomerServiceManagement") }}
				</p>
			</div>

			<div class="side_nav" v-if="!isRegister && !isResetPassword">
				<img
					class="w-100"
					:src="
						require('/src/assets/image/Shared/Login/cubes-2492010_1280.webp')
					"
				/>
			</div>
			<div class="login_component">
				<div class="login_account">
					<el-form-item
						prop="account"
						:label="$t('Login.ID')"
						class="mb-0 pb-2"
					>
						<el-input
							type="email"
							class="inner-input"
							ref="autoFocus"
							v-model="account"
							autocomplete="on"
							:placeholder="$t('Login.InputEmail')"
						/>
					</el-form-item>
					<el-form-item
						prop="password"
						:label="$t('Login.Password')"
						class="mb-0 pb-2"
					>
						<el-input
							type="password"
							v-model="password"
							autocomplete="on"
							class="inner-input w-100"
							@keydown.enter.prevent="login"
							show-password
							:placeholder="$t('Login.InputPassword')"
						/>
					</el-form-item>
					<el-form-item prop="key" :label="$t('Login.Key')" class="mb-0 pb-3">
						<el-input
							type="password"
							v-model="key"
							autocomplete="on"
							class="inner-input w-100"
							show-password
							:placeholder="$t('Login.InputKey')"
						/>
					</el-form-item>
				</div>
				<ConfirmButton
					class="login_btn"
					@click="login()"
					:text="$t('Login.Login')"
				></ConfirmButton>
			</div>
			<p class="tip d-none">＊想要加入的營主朋友,歡迎加喬米Line官方詢問</p>

			<div class="d-flex justify-content-between align-items-end">
				<p class="copyRight">Copyright © 2024 ChargeMe.</p>
				<div class="d-flex align-items-end">
					<el-dropdown class="me-2" @command="changeLanguage">
						<el-button type="link" class="text-dark" size="medium" circle>
							{{ selectedLang }}</el-button
						>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item
									v-for="i in languages"
									:command="i.code"
									:key="i"
								>
									<span class="ms-1">{{ i.label }}</span></el-dropdown-item
								>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
					<img
						class="logoImg"
						src="https://chargemestorage.blob.core.windows.net/chargeme-image/喬米LOGO (10).png"
						alt=""
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Storage from "../../../models/storage";
import ConfirmButton from "../../../components/Common/CommonElement/ConfirmButton.vue";

//import LoginComponent from "@/components/Shared/Login/LoginComponent.vue";
//import BackButton from "../../../components/Common/CommonElement/BackButton.vue";

export default {
	name: "Login",
	data() {
		return {
			languages: [
				{
					code: "zh_tw",
					label: "繁體中文",
					flag: "https://flagcdn.com/h20/hk.png",
					selectStr: "繁",
				},
				{
					code: "en_us",
					label: "English",
					flag: "https://flagcdn.com/h20/us.png",
					selectStr: "EN",
				},
				{
					code: "ja_jp",
					label: "日本語",
					flag: "https://flagcdn.com/h20/jp.png",
					selectStr: "日",
				},
				{
					code: "zh-cn",
					label: "简体中文",
					flag: "https://flagcdn.com/h20/cn.png",
					selectStr: "简",
				},
			],
			account: "",
			password: "",
			key: "",
		};
	},
	components: {
		//	LoginComponent,
		//BackButton,
		ConfirmButton,
	},
	methods: {
		changeLanguage(lang) {
			this.$i18n.locale = lang; // 更新 i18n 語系
			localStorage.setItem("locale", lang); // 儲存語系到 localStorage
		},
		async login() {
			const vm = this;
			const user = {
				account: vm.account,
				password: vm.password,
				key: vm.key,
			};
			await vm.$store
				.dispatch("auth/login", user)
				.then(() => {
					let name = vm.$store.state.auth.user.name;
					let message =
						name === ""
							? this.i18n_loginSuccess
							: `${name} ${this.i18n_WelcomeBack}`;
					vm.notify("success", message);
					vm.$router.push("/ChargeMeEnterpriseGPT/Home");
				})
				.catch((err) => {
					console.log(err.response.data);
					Storage.remove("user");
					vm.notify("error", `登入失敗，${err.response.data.Msg}!`);
				});
		},
	},
	mounted() {
		let vm = this;

		if (
			this.$route.query.openCampLineResponse != undefined &&
			this.$route.query.openCampLineResponse == "1"
		) {
			this.$store.state.isOpenCampLineResponse = true;
		}

		if (vm.$store.state.auth.user != null) {
			vm.$router.push("/ChargeMeEnterpriseGPT/Home");
		}
	},
	computed: {
		selectedLang() {
			return this.languages.find((lang) => lang.code === this.$i18n.locale)
				?.selectStr;
		},
		selectedFlag() {
			return this.languages.find((lang) => lang.code === this.$i18n.locale)
				?.flag;
		},
		isLoginShow() {
			return this.$route.path === "/login";
		},
		isRegister() {
			return this.$route.path.startsWith("/login/register");
		},
		isForgetPassword() {
			return this.$route.path.startsWith("/login/forgetPassword");
		},
		isResetPassword() {
			return this.$route.path.startsWith("/login/resetPassword");
		},
		isVerifyEmail() {
			return this.$route.path.startsWith("/login/verifyEmail");
		},
		i18n_loginSuccess() {
			return this.$t("Login.Script.LoginSuccess");
		},
		i18n_WelcomeBack() {
			return this.$t("Login.Script.WelcomeBack");
		},
	},
};
</script>

<style lang="scss" scoped>
.login_btn {
	width: 100%;
	border-radius: 10px;
	margin-bottom: 1rem;
}
.enterpriseLogo {
	font-size: 40px;
	font-weight: 600;
}
.enterpriseOnwer {
	font-size: 16px;
	font-weight: 600;
	margin-top: 20px;
	color: black;
}

::v-deep .el-input__wrapper {
	box-shadow: none;
	border: none;
	background-color: #ffffff;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
	padding: 4px 8px;
	border-radius: 4px;
	width: 100%;
	border-radius: 1rem;
}

@keyframes floatAnimation {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px); /* 向上移动10像素 */
	}
	100% {
		transform: translateY(0);
	}
}

/* 应用动画样式 */
.login_center {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tip {
	font-size: 12px;
	font-weight: 400;
	text-align: start;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.login {
	height: 100%;
	padding: 1rem 1rem;
	background: white;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.bgImg {
		position: relative;
		z-index: -1;
		top: 0;
		// height: 15rem;
	}
	.logo {
		margin-top: -10rem;
		margin-bottom: -3rem;
		font-size: 35px;
		height: 5rem;
		font-weight: 900;
		color: #7b7b7b;
	}
	.side_nav {
		margin-top: 1rem;
		margin-bottom: 1rem;
		position: relative;
		border-radius: 20px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			opacity: 0.7;
		}
		i {
			color: #fff;
			font-size: 5rem;
			font-weight: 900;
			margin-right: 1rem;
		}
		p {
			text-align: start;
			color: #fff;
			font-size: 1.5rem;
			width: 13rem;
		}
		.aiplane {
			animation: floatAnimation 3s ease infinite;
			position: absolute;
			width: 45%;
			top: 5%;
			left: 1%;
		}
	}
	.backHome {
		display: block;
		text-align: start;
		margin-bottom: 1rem;
		color: #afafaf;
		font-size: 1rem;
		span {
			color: #afafaf;
			// font-size: 0.8rem;
			margin-left: 0.5rem;
		}
	}
	.login_component {
		// margin-top: auto;
	}
}
.login {
	width: 100%;
	overflow: hidden;
	border-radius: 1rem;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	max-width: 400px;
	margin: 0 20px;
	justify-content: center;
	height: unset;
	.logo {
		margin-top: 0;
		margin-bottom: 0;
	}
	.bgImg {
		border-radius: 0px 0px 50% 0%;
		max-width: 400px;
		height: 10rem;
	}
}
.tentSvg {
	position: absolute;
	// top: -30px;
	width: 30px;
	right: -40px;
	bottom: 0;
}
.copyRight {
	font-size: 10px;
}
.logoImg {
	width: 80px;
	margin-top: 1rem;
}
</style>
